import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { CurdService } from '../shared/services/curd.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '../shared/services/toast.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],

  });
  show: boolean = false;
  challenge_id: any = {};
  user_data: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private curd: CurdService,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private authService: AuthService,

  ) { }

  ngOnInit(): void {
    this.challenge_id = this.route.snapshot.paramMap.get('id');
  }

  goToLink() {
    let url = 'https://www.facebook.com/'
    window.open(url)

  }

  showPassword() {
    this.show = !this.show;

  }

  login(value) {
    if (value.email == '' || value.password == '') {
      this.toast.inputError();
    } else {
      this.authService.loginUser(value)
        .then(res => {
          this.curd.query('user_challenges', ref => ref.where('uid', '==', res.user.uid)
            .where('challenge_id', '==', this.challenge_id)).subscribe(user_data => {
              this.user_data = user_data;
            });
          if (this.user_data.length == 0) {
            this.curd.show('users', res.user.uid).get().subscribe(user => {
              if (user.data()['current_status'] != 'disable') {
              }
            });
          }
          else {
            this.router.navigate(['/challenge-info', this.challenge_id, res.user.uid]);
            // this.toast.showError("You're Already Joined to this challenge");
            // this.router.navigate(['/confirm']);
          }
        }, err => {
          this.toast.showError('Something Went Wrong! Try Again')
        });
    }
  }

  signup() {
    this.router.navigate(['/register', this.challenge_id]);
  }
}


