// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fitbit_auth : {
    authorization: 'Basic MjNCQkxQOjg3YzhiMTBhYTcxYjMzZDNmM2Y0MzQwOGI1MGNkMjY3',
    client_id: '23BBLP',
    // redirect_uri: 'http://localhost:4200/fitbit'
    redirect_uri: 'https://tracker.fitlego.com/fitbit'
  },
  firebase: {
    apiKey: "AIzaSyBJWD3_7vSAjAZ6k0-zK50Sh7I2Yv7NKU8",
    authDomain: "fitlego-e1026.firebaseapp.com",
    databaseURL: "https://fitlego-e1026-default-rtdb.firebaseio.com",
    projectId: "fitlego-e1026",
    storageBucket: "fitlego-e1026.appspot.com",
    messagingSenderId: "845229506812",
    appId: "1:845229506812:web:fc83372f70df050cea1779",
    measurementId: "G-6V06CXSVC8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
