import { Component, OnInit,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  myWin:any = {}

    constructor(
    private router: Router,

      ) { }

  ngOnInit(): void {
  }

  // goToLink() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/dashboard'])
  //   );

  //   window.open(url, '_blank');
  // }
  // async ngOnDestroy() {
  //   let url = 'https://play.google.com/store/apps/details?id=com.fitlego.app'

  // }


  goToDownloadLink() {
    // this.router.navigate(['/confirm']);
    // let url = 'https://play.google.com/store/apps/details?id=com.fitlego.app'
    // window.open(url)
  }


}



