import { Injectable } from '@angular/core';
import {FirestoreService} from "../../services/firestore.service";

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  constructor(private firestore: FirestoreService) { }

  getMyReward(uid) {
    return this.firestore.doc('users/'+uid).get();
  }

  updatePoints(doc_id, points){
    return this.firestore.update('users/'+doc_id, {'reward_points': points});
  }
}
