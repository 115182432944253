import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {FirestoreService} from "../services/firestore.service";
import {AngularFireAuth} from "@angular/fire/auth";

@Component({
  selector: 'app-fitbit',
  templateUrl: './fitbit.component.html',
  styleUrls: ['./fitbit.component.scss']
})
export class FitbitComponent implements OnInit {

  code: string;
  uid: string

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private firestore: FirestoreService,
    private afAuth: AngularFireAuth,
  ) {
  }

  ngOnInit(): void {
    this.requestToken();
  }

  requestToken() {
    this.code = this.route.snapshot.queryParamMap.get('code')
    this.uid = this.route.snapshot.queryParamMap.get('state')

    const header = new HttpHeaders({
      Authorization: environment.fitbit_auth.authorization,
      'Content-Type': 'application/x-www-form-urlencoded'
    })

    const url = `https://api.fitbit.com/oauth2/token?client_id=${environment.fitbit_auth.client_id}&grant_type=authorization_code&code=${this.code}&redirect_uri=${environment.fitbit_auth.redirect_uri}`
    this.http.post(url, {}, {headers: header}).subscribe(res => {
      this.afAuth.signInWithEmailAndPassword('tracking@fitlego.com.au', '123456').then(() => {
        console.log('IN');
        this.firestore.set(`tracker/${this.uid}`, {
          fitbit_data: res,
          default_tracker: 'fitbit'
        }).then(() => {
          console.log('Fitbit Data Saved')
        }).catch(e => {
          console.log(e);
        })
      })
    })

  }

}
