import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CurdService } from '../shared/services/curd.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private curd: CurdService,
  ) { }

   // Sign in with email/password
   SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.curd.show('users', result.user['uid']).get().subscribe(res => {
            if (res.data()['current_status']!="disable") {
              this.router.navigate(['dashboard']);
            } else {
              this.SignOut().then(() => {
                // this.router.navigate(['login']);
              });
            }
          });
        });

      }).catch((error) => {
        window.alert(error.message);
      });
  }

  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
        this.afAuth.signInWithEmailAndPassword(value.email, value.password)
            .then(
                res => resolve(res),
                err => reject(err))
    })
}

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      // this.router.navigate(['login']).then(r => { });
    });
  }
}
