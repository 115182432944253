<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content="Betler Multipurpose Forms HTML Template" />
  <meta name="author" content="Ansonika" />
  <title>Betler | Multipurpose Forms HTML Template</title>

  <!-- Favicons-->
  <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon" />
  <link rel="apple-touch-icon" type="image/x-icon" href="img/apple-touch-icon-57x57-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="72x72" href="img/apple-touch-icon-72x72-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="114x114"
    href="img/apple-touch-icon-114x114-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="144x144"
    href="img/apple-touch-icon-144x144-precomposed.png" />

  <!-- GOOGLE WEB FONT -->
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
    rel="stylesheet" />

  <!-- BASE CSS -->
</head>

<body>

  <!-- /Preload -->

  <div class="container-fluid p-0">
    <div class="row no-gutters row-height">
      <div class="col-lg-6 background-image">
        <div class="content-left-wrapper opacity-mask" data-opacity-mask="rgba(58, 65, 194, 0.85)">
          <a href="#0" id="logo"><img src="../../assets/img/logo.png" alt="" style="width: 150px; height: auto" /></a>
          <div id="social">
            <ul>
              <li>
                <a href="#0"><i class="social_facebook"></i></a>
              </li>
              <li>
                <a href="#0"><i class="social_twitter"></i></a>
              </li>
              <li>
                <a href="#0"><i class="social_instagram"></i></a>
              </li>
            </ul>
          </div>
          <!-- /social -->
          <div>
            <h1>Fitlygo Login</h1>
            <p>
              The Fitlygo app enables you to count steps, distance and workout
              time, and to calculate the calories you burn. Not only will we
              help you stay motivated when you join a challenge with your
              buddies or work colleagues but we will also give you lots of
              tips and prizes along the way.
            </p>
            <a class="btn_1 black rounded pulse_bt  btn_play"  href="https://fitlygo.com">Visit Fitlygo.com<i
                class="arrow_triangle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex flex-column content-right">
        <div class="container my-auto py-5">
          <div class="row">
            <div class="col-lg-9 col-xl-7 mx-auto">
              <h4 class="mb-3 login-text">Login With Your Fitlygo Account</h4>
              <form class="input_style_1" method="post" [formGroup]="loginForm">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label>Email</label>
                    <input type="email" name="email_address" id="email_address" class="form-control"
                      formControlName="email" placeholder="Email" />
                  </div>
                </div>
                <!-- <div class="form-row">
                                  <div class="form-group col-md-9">
                                    <label>Password</label>
                                    <input type="password" name="password" id="password" [type]="show ? 'text' : 'password'"class="form-control" formControlName="password"
                                    placeholder="Password" />
                                  </div>
                                  <div class="form-group col-md-3">
                                    <button type="submit" class="btn_1 full-width show-pw-btn" (click)="showPassword()">Show</button>
                                  </div>
                                </div> -->
                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" name="password" id="password" [type]="show ? 'text' : 'password'"
                      class="form-control" formControlName="password" placeholder="Password" />
                    <button class="btn btn-light ms-0 login-inputs" type="button" id="password-addon"
                      (click)="showPassword()"><i class="fa fa-eye"></i></button>
                  </div>
                </div>
                <!-- <div class="clearfix mb-3">
                    <div class="float-left">
                      <label class="container_check"
                        >Remember Me
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="float-right">
                      <a id="forgot" href="javascript:void(0);"
                        >Forgot Password?</a
                      >
                    </div>
                  </div> -->
                <button type="submit" class="btn_1 full-width" (click)="login(loginForm.value)">Login</button>
              </form>
              <p class="text-center mt-3 mb-0">
                Don't have an account? <a href="javascript:void(0)" (click)="signup()">Sign Up</a>
              </p>
              <form class="input_style_1" method="post">
                <div id="forgot_pw">
                  <h4 class="mb-3">Forgot Password</h4>
                  <div class="form-group">
                    <label for="email_forgot">Login email</label>
                    <input type="email" class="form-control" name="email_forgot" id="email_forgot" />
                  </div>
                  <p>
                    You will receive an email containing a link allowing you
                    to reset your password to a new preferred one.
                  </p>
                  <div class="text-center">
                    <input type="submit" value="Reset Password" class="btn_1" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container pb-3 copy">
          © 2025 | fitlygo.com | All rights received by SKAND TECH PTY LTD
          [ABN 18 653 803 902]
        </div>
      </div>
    </div>
    <!-- /row -->
  </div>
  <!-- /container -->

  <!-- Modal terms -->
  <div class="modal fade" id="terms-txt" tabindex="-1" role="dialog" aria-labelledby="termsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in
            <strong>nec quod novum accumsan</strong>, mei ludus tamquam
            dolores id. No sit debitis meliore postulant, per ex prompta
            alterum sanctus, pro ne quod dicunt sensibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
            novum accumsan, mei ludus tamquam dolores id. No sit debitis
            meliore postulant, per ex prompta alterum sanctus, pro ne quod
            dicunt sensibus. Lorem ipsum dolor sit amet,
            <strong>in porro albucius qui</strong>, in nec quod novum
            accumsan, mei ludus tamquam dolores id. No sit debitis meliore
            postulant, per ex prompta alterum sanctus, pro ne quod dicunt
            sensibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
            novum accumsan, mei ludus tamquam dolores id. No sit debitis
            meliore postulant, per ex prompta alterum sanctus, pro ne quod
            dicunt sensibus.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn_1" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</body>

</html>

