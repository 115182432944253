import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { RewardService } from "../shared/services/reward.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { CurdService } from '../shared/services/curd.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '../shared/services/toast.service';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  userForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    confirm_password: ['', Validators.required],
    nickname: ['', Validators.required],
    full_name: ['', Validators.required],
    country: ['', Validators.required],
    t_and_c: [false, Validators.required],
    phone: ['', Validators.required],
  });
  selected_country:any;
  countries = [];
  all_points: any = {};
  parent_uid: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reward: RewardService,
    private afAuth: AngularFireAuth,
    private curd: CurdService,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private userService: UserService,
  ) {
    this.countries = this.userService.getAllCountries();

  }

  ngOnInit(): void {
    this.parent_uid = this.route.snapshot.paramMap.get('uid');
    this.afAuth.signInWithEmailAndPassword('public@fitlego.com.au', '123456').then(() => {
    });
    this.loadCountryList();
  }

  loadCountryList() {
    this.countries = this.userService.getAllCountries();
    this.countries.sort((a, b) =>
      a.name.localeCompare(b.name));
  }

  filterDropdown(e) {
    if (e) {
      let input_value = e.toLowerCase();
      this.countries = this.countries.filter((obj) => obj.name.toLowerCase().indexOf(input_value) > -1);
      if(this.countries.length !=0){
        this.countries
      }else{
      this.loadCountryList();
      }
    } else {
      this.loadCountryList();
    }
  }

  selectValue(name) {
    this.selected_country = name;
  }


  validateInput(inputs) {
    if (!inputs.full_name) {
      this.toast.showError('Fill Full Name');
      return false;
    }

    if (!inputs.phone) {
      this.toast.showError('Fill Phone Number');
      return false;
    }

    if (!inputs.nickname) {
      this.toast.showError('Fill Nickname');
      return false;
    }
    if (!inputs.email) {
      this.toast.showError('Fill Email');
      return false;
    }

    if (!this.selected_country) {
      this.toast.showError('Select Country');
      return false;
    }
    if (!inputs.password) {
      this.toast.showError('Fill Password Correctly');
      return false;
    }
    if (!inputs.confirm_password) {
      this.toast.showError('Fill Passwords Correctly');
      return false;
    }
    if (inputs.password != inputs.confirm_password) {
      this.toast.showError('Fill Passwords Correctly');
      return false;
    }

    if (!inputs.t_and_c) {
      this.toast.showError('! You must agree with T&C');
      return false;
    }

    return true;
  }

  async save(value) {
    if (this.validateInput(value)) {
      let country = this.countries.filter(arr => arr.name == this.selected_country);
      let country_details = {
        code: country[0].code,
        dial_code: country[0].dial_code,
        name: country[0].name
      }
      await this.afAuth.createUserWithEmailAndPassword(value.email, value.password)
        .then(async res => {
          const input_data = {
            uid: res.user.uid,
            email: value.email,
            emailVerified: false,
            nickname: value.nickname,
            full_name: value.full_name,
            phone: '',
            avatar: "",
            join_date: new Date(),
            reward_points: 500,
            refferd_by: this.parent_uid,
            country: country_details,
            height: '',
            weight: '',
            fcm_token: '',
            point_history: []
          };

          await this.curd.setWithID(`users/${res.user.uid}`, input_data).then(res => {
            this.refferalBonus();
            this.toast.success();
          }).catch((e: any) => {
            this.toast.showError('Something Went Wrong! Try Again')
            console.log(e);
          });
        });
    }
  }

  refferalBonus() {
    this.reward.getMyReward(this.parent_uid).subscribe(res => {
      const points = res.data()['reward_points'];
      this.reward.updatePoints(this.parent_uid, points + 200).then(() => {
        console.log('Points Updated');
      });

      this.curd.show('users', this.parent_uid).get().subscribe(res => {
        if (res.data()['point_history']) {
          this.all_points = res.data()['point_history'];
          if (this.all_points.referel_points_l1) {
            this.all_points['referel_points_l1'] = this.all_points.referel_points_l1 + 200;
          } else {
            this.all_points['referel_points_l1'] = 200;
          }
        } else {
          this.all_points['referel_points_l1'] = 200;
        }


        const points_update = {
          point_history: this.all_points
        };

        this.curd.update('users', this.parent_uid, points_update).then(refferance => {
          console.log('updated');
          this.userForm.reset();
        }).catch(e => {

        });
      });
    })
  }

  async facebookSignIn() {
    const FACEBOOK_PERMISSIONS = ['email'];
    const result = <FacebookLoginResponse><unknown>FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then(res => {
      console.log('result>>');
      console.log(res);
    }).catch(err => {
      console.log(err);
    });

    if (result.accessToken) {
      // Login successful.
      console.log(`Facebook access token is ${result.accessToken.token}`);
    } else {
      console.log('Loin has error');
    }
  }

   goToLink() {
    let url = 'https://www.fitlygo.com/'
    window.open(url)
  }

}
