import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FitbitComponent} from './fitbit/fitbit.component';
import {InviteComponent} from './invite/invite.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ChallengeInfoComponent} from './challenge-info/challenge-info.component';
import {ConfirmComponent} from './confirm/confirm.component';


const routes: Routes = [
  {path: 'fitbit', component: FitbitComponent},
  {
    path: 'invite/:uid',
    component: InviteComponent
  },
  // { path: '', pathMatch: 'full', redirectTo: 'www.fitlego.com' },
  {
    path: 'challenge/:id',
    component: LoginComponent
  },
  {
    path: 'login/:id',
    component: LoginComponent
  },
  {
    path: 'register/:id',
    component: RegisterComponent
  },
  {
    path: 'challenge-info/:challenge_id/:user_id',
    component: ChallengeInfoComponent
  },
  {
    path: 'confirm',
    component: ConfirmComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
