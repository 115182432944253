<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content="Betler Multipurpose Forms HTML Template" />
  <meta name="author" content="Ansonika" />
  <title>Betler | Multipurpose Forms HTML Template</title>

  <!-- Favicons-->
  <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon" />
  <link rel="apple-touch-icon" type="image/x-icon" href="img/apple-touch-icon-57x57-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="72x72" href="img/apple-touch-icon-72x72-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="114x114"
    href="img/apple-touch-icon-114x114-precomposed.png" />
  <link rel="apple-touch-icon" type="image/x-icon" sizes="144x144"
    href="img/apple-touch-icon-144x144-precomposed.png" />

  <!-- GOOGLE WEB FONT -->
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
    rel="stylesheet" />

  <!-- BASE CSS -->
</head>

<body>

  <!-- /Preload -->

  <div class="container-fluid p-0">
    <div class="row no-gutters row-height">
      <div class="col-lg-6 background-image">
        <div class="content-left-wrapper opacity-mask" data-opacity-mask="rgba(58, 65, 194, 0.85)">
          <a href="#0" id="logo"><img src="../../assets/img/logo.png" alt="" style="width: 150px; height: auto" /></a>
          <div id="social">
            <ul>
              <li>
                <a href="#0"><i class="social_facebook"></i></a>
              </li>
              <li>
                <a href="#0"><i class="social_twitter"></i></a>
              </li>
              <li>
                <a href="#0"><i class="social_instagram"></i></a>
              </li>
            </ul>
          </div>
          <!-- /social -->
          <div>
            <h1>Join Fitlygo Challenge</h1>
            <p>
              The Fitlygo app enables you to count steps, distance and workout
              time, and to calculate the calories you burn. Not only will we
              help you stay motivated when you join a challenge with your
              buddies or work colleagues but we will also give you lots of
              tips and prizes along the way.
            </p>
            <a  class="btn_1 black rounded pulse_bt btn_play" href="https://fitlygo.com">Visit Fitlygo.com<i
                class="arrow_triangle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex flex-column content-right">
        <div class="container my-auto py-5">
          <div class="row">
            <div class="col-lg-9 col-xl-7 mx-auto">
              <h4 class="mb-3 login-text">Register As New Fitlygo Account</h4>
              <form class="input_style_1" method="post" [formGroup]="userForm">
                <label> Name</label>
                <div class="form-group">
                  <input placeholder="Full Name" type="text" class="form-control" formControlName="full_name" />
                </div>
                <label>Nick Name</label>
                <div class="form-group">
                  <input placeholder="Nick Name" type="text" class="form-control" formControlName="nickname"  (change)="getUsers($event)" />
                  <!-- <div class="already-text" *ngIf="nickname_duplicate==true">
                   This Nickname Already Used
                  </div> -->
                </div>
                <label>Email Address</label>
                <div class="form-group">
                  <input placeholder="Email" type="email" class="form-control" formControlName="email" />
                </div>
                <label>Select Country</label>
                <div class="form-group">
                  <!-- <ng-select [items]="countries" appendTo="body" formControlName="phone" placeholder="Select Fruits"></ng-select> -->
                  <!-- <ngx-select-dropdown [config]="config" [options]="countries"></ngx-select-dropdown> -->
                  <div class="dropdown h-100" class="statusDropdownContainer">
                    <a class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle" href="#" role="button"
                      id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="text-truncate">Country: {{selected_country ? selected_country : 'Select Country'}}</span>
                    </a>
                    <div class="dropdown-menu pt-0 select-style" aria-labelledby="dropdownMenuButton " >
                      <input type="text" class="w-100 p-2 searchInput"  formControlName="country" (ngModelChange)="filterDropdown($event)">
                      <a *ngFor="let option of countries; let i=index"  class="dropdown-item pointer text-contain"
                        [ngClass]="{'alternateBackground': i%2 == 0 }" (click)="selectValue(option.name)">
                        {{option.name}}
                      </a>
                      <div *ngIf="countries.length <=0" class="text-center text-muted mt-1">No Country found</div>
                    </div>
                  </div>
                </div>
                <label>Phone Number</label>
                <div class="form-group">
                  <input placeholder="Phone Number" type="tel" #phone (keydown)="onKey($event)" (keyup)="onKeyUp($event)" value="{{dial_cade}}" class="form-control"  maxlength="14"formControlName="phone" />
                </div>
                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" name="password1" id="password1" [type]="show_password ? 'text' : 'password'"
                      class="form-control" formControlName="password" placeholder="Password" />
                    <button class="btn btn-light ms-0 login-inputs" type="button" id="password-addon"
                      (click)="showPassword('password')"><i class="fa fa-eye"></i></button>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="password">Confirm Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" name="password2" id="password2" [type]="show_confirm_password ? 'text' : 'password'"
                      class="form-control" formControlName="confirm_password" placeholder="Confirm Password" />
                    <button class="btn btn-light ms-0 login-inputs" type="button" id="password-addon"
                      (click)="showPassword('confirm_password')"><i class="fa fa-eye"></i></button>
                  </div>
                </div>
                <div id="pass-info" class="clearfix"></div>
                <div class="mb-4">
                  <label class="container_check">I agree to the
                    <a href="#" data-toggle="modal" data-target="#terms-txt">Terms and Privacy Policy</a>.
                    <input type="checkbox" formControlName="t_and_c" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <button type="submit" class="btn_1 full-width"  [disabled]="nickname_duplicate==true" (click)="save(userForm.value)">
                  Sign Up
                </button>
              </form>
              <p class="text-center mt-3 mb-0">
                Already have an account? <a [routerLink]="['/login', challenge_id]">Sign In</a>
              </p>
            </div>
          </div>
        </div>
        <div class="container pb-3 copy">
          © 2021 | fitlygo.com | All rights received by SKAND TECH PTY LTD
          [ABN 18 653 803 902]
        </div>
      </div>
    </div>
    <!-- /row -->
  </div>
  <!-- /container -->

  <!-- Modal terms -->
  <div class="modal fade" id="terms-txt" tabindex="-1" role="dialog" aria-labelledby="termsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in
            <strong>nec quod novum accumsan</strong>, mei ludus tamquam
            dolores id. No sit debitis meliore postulant, per ex prompta
            alterum sanctus, pro ne quod dicunt sensibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
            novum accumsan, mei ludus tamquam dolores id. No sit debitis
            meliore postulant, per ex prompta alterum sanctus, pro ne quod
            dicunt sensibus. Lorem ipsum dolor sit amet,
            <strong>in porro albucius qui</strong>, in nec quod novum
            accumsan, mei ludus tamquam dolores id. No sit debitis meliore
            postulant, per ex prompta alterum sanctus, pro ne quod dicunt
            sensibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
            novum accumsan, mei ludus tamquam dolores id. No sit debitis
            meliore postulant, per ex prompta alterum sanctus, pro ne quod
            dicunt sensibus.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn_1" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</body>

</html>
