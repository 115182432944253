import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CurdService } from '../shared/services/curd.service';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-challenge-info',
  templateUrl: './challenge-info.component.html',
  styleUrls: ['./challenge-info.component.scss']
})
export class ChallengeInfoComponent implements OnInit {
  employee_id:any;
  all_user_challenges: any[];
  employee_id_duplicate = false;
  corporate = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private curd: CurdService,
    private authService: AuthService,
    private toast: ToastService,
  ) { }

  challenge_id: any = {};
  user_id: any = {};
  corporate_id=false;
  // challenge={
  //   goal:'',
  //   name:'',
  //   prize:'',
  //   start:'',
  //   end:'',
  //   challenge_status:'',
  // };
  challenge:any;
  ngOnInit(): void {
    this.loadUserChallenges();
    this.index();
    this.user_id = this.route.snapshot.paramMap.get('user_id');
    this.challenge_id = this.route.snapshot.paramMap.get('challenge_id');
  }


  index() {
    this.challenge_id = this.route.snapshot.paramMap.get('challenge_id');
    this.user_id = this.route.snapshot.paramMap.get('user_id');
    this.curd.show('challenges', this.challenge_id).get().subscribe(res => {
      this.challenge=res.data();

      // goal:'',
      // name:'',
      // prize:'',
      // start:'',
      // end:'',
      // challenge_status:'',
      console.log( this.challenge)
      if (this.challenge.corporate_id) {
        this.corporate_id = true;
      }
      else {
        this.corporate_id =false;
      }
    });
  }

  loadUserChallenges() {
    this.curd.index('user_challenges').subscribe(res => {
      this.all_user_challenges = res.filter(obj => obj.challenge_id == this.challenge_id);

    });
  }

  checkEmployeeId(e) {
    if (e) {
      const employee_id_filter = this.all_user_challenges.filter(obj => obj.employee_id == e.target.value);
      if (employee_id_filter.length != 0) {
        this.employee_id_duplicate = true
      }
      if (employee_id_filter.length == 0) {
        this.employee_id_duplicate = false
      }
    }
  }

  joinChallenge(value) {
    console.log(value)
    if(value=='corporate'){
      if (this.employee_id == '') {
        this.toast.showError('Please Enter Employee ID');
      }
      else {
        let input = {};
        input['challenge_id'] = this.challenge_id;
        input['uid'] = this.user_id;
        input['status'] = 'pending';
        input['employee_id'] = this.employee_id;
        const goals = {
          distance: 0,
          steps: 0
        }
        input['goals'] = goals;
        this.curd.store('user_challenges', input).then(res => {
          this.router.navigate(['/confirm']);
        }).catch(e => {
          console.log(e);
        });
      }
    }else{
      let input = {};
      input['challenge_id'] = this.challenge_id;
      input['uid'] = this.user_id;
      input['status'] = 'Joined';
      // input['corporate_id'] = employee_id;
      const goals = {
        distance: 0,
        steps: 0
      }
      input['goals'] = goals;
      this.curd.store('user_challenges', input).then(res => {
        this.router.navigate(['/confirm']);
      }).catch(e => {
        console.log(e);
      });
    }
  }

}
