<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta
      name="description"
      content="Betler Multipurpose Forms HTML Template"
    />
    <meta name="author" content="Ansonika" />
    <title>Betler | Multipurpose Forms HTML Template</title>

    <!-- Favicons-->
    <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon" />
    <link
      rel="apple-touch-icon"
      type="image/x-icon"
      href="img/apple-touch-icon-57x57-precomposed.png"
    />
    <link
      rel="apple-touch-icon"
      type="image/x-icon"
      sizes="72x72"
      href="img/apple-touch-icon-72x72-precomposed.png"
    />
    <link
      rel="apple-touch-icon"
      type="image/x-icon"
      sizes="114x114"
      href="img/apple-touch-icon-114x114-precomposed.png"
    />
    <link
      rel="apple-touch-icon"
      type="image/x-icon"
      sizes="144x144"
      href="img/apple-touch-icon-144x144-precomposed.png"
    />

    <!-- GOOGLE WEB FONT -->
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    />

    <!-- BASE CSS -->
  </head>

  <body>

    <!-- /Preload -->

    <div class="container-fluid p-0">
      <div class="row no-gutters row-height">
        <div class="col-lg-6 background-image">
          <div
            class="content-left-wrapper opacity-mask"
            data-opacity-mask="rgba(58, 65, 194, 0.85)"
          >
            <a href="#0" id="logo"
              ><img
                src="../../assets/img/logo.png"
                alt=""
                style="width: 150px; height: auto"
            /></a>

            <div>
              <h1>Join Challenge</h1>
              <!-- <p>
                The Fitlygo app enables you to count steps, distance and workout
                time, and to calculate the calories you burn. Not only will we
                help you stay motivated when you join a challenge with your
                buddies or work colleagues but we will also give you lots of
                tips and prizes along the way.
              </p> -->
              <!-- <a
                href="#"
                class="btn_1 black rounded pulse_bt plus_icon btn_play"
                >Play Video Intro<i class="arrow_triangle-right"></i
              ></a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column content-right">
          <div class="container my-auto py-5">
            <div class="row">
              <div class="col-lg-9 col-xl-7 mx-auto">
                <h4 class="mb-3">Challenge Information</h4>
                <div class="h6 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Challenge Name -
                  {{ challenge.name }}
                </div>
                <div class="h6 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Challenge Goal -
                  {{ challenge.goal }}
                </div>
                <div class="h6 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Challenge Prize -
                  {{ challenge.prize }}
                </div>
                <div class="h6 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Start Date -
                  {{ challenge.start }}
                </div>
                <div class="h6 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>End Date -
                  {{ challenge.end }}
                </div>
                <div class="h6 mt-4">
                  <i class="ni education_hat mr-2"></i>Challenge Status -
                  {{ challenge.challenge_status }}
                </div>
                <div >
                <div class="h6 mt-4" *ngIf="corporate_id==true" >
                  <i class="ni education_hat mr-2"></i>Employee ID - &nbsp;
                  <input [(ngModel)]="employee_id"(change)="checkEmployeeId($event)"  class="form-control form-control-sm">
                  <div class="already-text-employee" *ngIf="employee_id_duplicate==true">
                    This Employee ID Already Used..Use Another Employee ID
                   </div>
                </div>
                </div>
                <br>
                  <button type="button" *ngIf="corporate_id==true" class="btn_1 full-width" [disabled]="employee_id_duplicate==true" (click)="joinChallenge('corporate')">Join Challenge</button>
                  <button type="button" *ngIf="corporate_id==false"  class="btn_1 full-width"  (click)="joinChallenge('other')">Join Challenge</button>
              </div>
            </div>
          </div>
          <div class="container pb-3 copy">
            © 2024 | fitlygo.com | All rights received by SKAND TECH PTY LTD
            [ABN 18 653 803 902]
          </div>
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->

    <!-- Modal terms -->
    <div
      class="modal fade"
      id="terms-txt"
      tabindex="-1"
      role="dialog"
      aria-labelledby="termsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in
              <strong>nec quod novum accumsan</strong>, mei ludus tamquam
              dolores id. No sit debitis meliore postulant, per ex prompta
              alterum sanctus, pro ne quod dicunt sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus. Lorem ipsum dolor sit amet,
              <strong>in porro albucius qui</strong>, in nec quod novum
              accumsan, mei ludus tamquam dolores id. No sit debitis meliore
              postulant, per ex prompta alterum sanctus, pro ne quod dicunt
              sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn_1" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
