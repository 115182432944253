import { Component,  ViewChild, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from "@angular/fire/auth";
import { CurdService } from '../shared/services/curd.service';
import { ToastService } from '../shared/services/toast.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  countries = [];
  challenge_id: any = {};
  nickname_duplicate = false;
  selected_country: any;
  dial_cade:any;
  show_confirm_password: boolean = false;
  show_password: boolean = false;

  @ViewChild('phone') phone: ElementRef
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fromBuilder: FormBuilder,
    private afAuth: AngularFireAuth,
    private curd: CurdService,
    private toast: ToastService,
    private userService: UserService,
  ) {
  }

  userForm = this.fromBuilder.group({
    email: new FormControl('',Validators.compose([Validators.required])),
    password: new FormControl('',Validators.compose([Validators.required])),
    confirm_password: new FormControl('',Validators.compose([Validators.required])),
    nickname: new FormControl('',Validators.compose([Validators.required])),
    full_name:new FormControl('',Validators.compose([Validators.required])),
    phone: new FormControl('',Validators.compose([Validators.required])),
    t_and_c:new FormControl(false,Validators.compose([Validators.required])),
    country: new FormControl('',Validators.compose([Validators.required])),

  });

  ngOnInit(): void {
    this.challenge_id = this.route.snapshot.paramMap.get('id');
    this.getUsers('');
    this.loadCountryList();
  }

  showPassword(value) {
    if(value=='password'){
      this.show_password = !this.show_password;
    }
    if(value=='confirm_password'){
      this.show_confirm_password = !this.show_confirm_password;
    }
  }

  onKeyUp(e: any) {
    const value = this.phone.nativeElement.value
    if(!(/^\+[\d ]*$/.test(value))) {
      this.phone.nativeElement.value = value.slice(0, -1)
    }
  }

  onKey(e: any) {
    const value = this.phone.nativeElement.value
    if(e.which === 8) {
      return
    }

    const len = value.length
    if(len === 4 || len === 8 || len === 12) {
      this.phone.nativeElement.value = value + ' '
    }

    if(len >= 16) {
      e.preventDefault()
    }
  }
  loadCountryList() {
    this.countries = this.userService.getAllCountries();
    this.countries.sort((a, b) =>
      a.name.localeCompare(b.name));
  }

  filterDropdown(e) {
    if (e) {
      let input_value = e.toLowerCase();
      this.countries = this.countries.filter((obj) => obj.name.toLowerCase().indexOf(input_value) > -1);
      if (this.countries.length != 0) {
        this.countries
      } else {
        this.loadCountryList();
      }
    } else {
      this.loadCountryList();
    }
    // window.scrollTo(window.scrollX, window.scrollY + 1);
    // let searchString = e.toLowerCase();
    // if (!searchString) {
    //   this.loadCountryList();
    //   return
    // } else {
    //   this.countries = this.countries.filter(
    //     obj => obj.name.toLowerCase().indexOf(searchString) > -1
    //   );
    // }
    // window.scrollTo(window.scrollX, window.scrollY - 1);
  }

  selectValue(name) {
    this.selected_country = name;
    this.dial_cade = this.countries.filter((obj) => obj.name == this.selected_country);
    this.dial_cade = this.dial_cade[0].dial_code
  }

  getUsers(e) {
    this.curd.index('users').subscribe((res) => {
      if (e) {
        const input_value = e.target.value
        const users_filter = res.filter((obj) => obj.nickname
          .toUpperCase()
          .includes(input_value.toUpperCase()));
        if (users_filter.length != 0) {
          this.toast.showError('This NickName Already Used');
        }
        if (users_filter.length == 0) {
        }
        //   if (e) {
        //     const users_filter = res.filter(obj => obj.nickname == e.target.value);
        //     if (users_filter.length != 0) {
        //       this.nickname_duplicate = true
        //     }
        //     if (users_filter.length == 0) {
        //       this.nickname_duplicate = false
        //     }
      }
    })
  }

  goToLink() {
    let url = 'https://www.fitlygo.com/'
    window.open(url)
  }

  validateInput(inputs) {
    if (!inputs.full_name) {
      this.toast.showError('Fill Full Name');
      return false;
    }
    if (!inputs.nickname) {
      this.toast.showError('Fill Nickname');
      return false;
    }
    if (!inputs.phone) {
      this.toast.showError('Fill Phone Number');
      return false;
    }
    if (!inputs.email) {
      this.toast.showError('Fill Email');
      return false;
    }
    if (!this.selected_country) {
      this.toast.showError('Select Country');
      return false;
    }
    if (!inputs.password) {
      this.toast.showError('Fill Password Correctly');
      return false;
    }
    if (!inputs.confirm_password) {
      this.toast.showError('Fill Passwords Correctly');
      return false;
    }
    if (inputs.password != inputs.confirm_password) {
      this.toast.showError('Fill Passwords Correctly');
      return false;
    }

    if (!inputs.t_and_c) {
      this.toast.showError('! You must agree with T&C');
      return false;
    }

    return true;
  }

  async save(value) {
    if (this.validateInput(value)) {
      let country = this.countries.filter(arr => arr.name == this.selected_country);
      let country_details = {
        code: country[0].code,
        dial_code: country[0].dial_code,
        name: country[0].name
      }
      await this.afAuth.createUserWithEmailAndPassword(value.email, value.password)
        .then(async res => {
          const input_data = {
            uid: res.user.uid,
            email: value.email,
            emailVerified: false,
            nickname: value.nickname,
            full_name: value.full_name,
            phone: value.phone,
            avatar: "",
            join_date: new Date(),
            reward_points: 500,
            country: country_details,
            height: '',
            weight: '',
            fcm_token: '',
            point_history: []
          };
          await this.curd.setWithID(`users/${res.user.uid}`, input_data).then(x => {
            this.router.navigate(['/challenge-info', this.challenge_id, res.user.uid]);
            this.toast.success();
          })
        }).catch(e => {
          this.toast.showError(e.message)
        });;
    }
  }
}
